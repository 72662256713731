import * as types from './mutation-types'
import Promoter from '../../resource_models/promoter'
import * as roleShipsActions from './actions/role_ship_actions.js'
import * as preferredCategoryShipActions from './actions/preferred_category_ship_actions'

export const fetchRoleShips = roleShipsActions.fetchRoleShips
export const createRoleShip = roleShipsActions.createRoleShip
export const destroyRoleShip = roleShipsActions.destroyRoleShip

export const fetchPreferredCategoryShips =
  preferredCategoryShipActions.fetchPreferredCategoryShips
export const createPreferredCategoryShip =
  preferredCategoryShipActions.createPreferredCategoryShip
export const destroyPreferredCategoryShip =
  preferredCategoryShipActions.destroyPreferredCategoryShip

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    Promoter.all(options)
      .then((response) => {
        if (options.noReplace) {
          commit(types.GET_RELATED_PROMOTERS_SUCCESS, response)
        } else {
          commit(types.FETCH_PROMOTERS_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Promoter.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_SUCCESS, response)

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchShares = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchShares')

  return new Promise((resolve, reject) => {
    model
      .fetchShares(options)
      .then((response) => {
        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchShares')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchShares,
            ref: {
              dispatch,
              commit
            },
            params: {
              model: model,
              options: options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchDashboardData = (
  { dispatch, commit },
  { dataType, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchDashboardData')
  return new Promise((resolve, reject) => {
    Promoter.fetchDashboardData(dataType, options)
      .then((response) => {
        commit(types.GET_DASHBOARD_DATA_SUCCESS, {
          response,
          dataType
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchDashboardData,
            ref: {
              dispatch,
              commit
            },
            params: {
              dataType,
              options
            }
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTERS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = (
  { commit },
  response
) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PROMOTERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_SUCCESS, response)

    resolve(response)
  })
}

/**
 * toggle Promoter `is_suspended` 的狀態
 *
 * @param {number} id 指定的 resource ID
 * @returns {promise} response or errors
 */
export const suspend = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'suspend')

  return new Promise((resolve, reject) => {
    model
      .suspend(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: suspend,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * toggle Promoter `is_allowed_to_add_child` 的狀態
 *
 * @param {number} id 指定的 resource ID
 * @returns {promise} response or errors
 */
export const allowToAddChild = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'allowToAddChild')

  return new Promise((resolve, reject) => {
    model
      .allowToAddChild(options)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: allowToAddChild,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchRoles = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchRoles')

  return new Promise((resolve, reject) => {
    Promoter.roles()
      .then((response) => {
        commit(types.FETCH_PROMOTER_ROLES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRoles,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updatePhotos = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'updatePhotos')

  return new Promise((resolve, reject) => {
    model
      .updatePhotos(formData)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePhotos,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

// update profile
export const updateProfile = ({ dispatch, commit }, { model, data }) => {
  commit(types.API_REQUEST_START, 'updateProfile')

  return new Promise((resolve, reject) => {
    model
      .updateProfile(data)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )
        reject(errors)
      })
  })
}

export const createWithExistedPromoterMember = (
  { dispatch, commit },
  model
) => {
  commit(types.API_REQUEST_START, 'createWithExistedPromoterMember')

  return new Promise((resolve, reject) => {
    model
      .createWithExistedPromoterMember()
      .then((response) => {
        commit(types.ADD_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createWithExistedPromoterMember,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createForPromoterEvent = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'createForPromoterEvent')

  return new Promise((resolve, reject) => {
    model
      .createForPromoterEvent()
      .then((response) => {
        commit(types.ADD_PROMOTER_SUCCESS, response)

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createForPromoterEvent,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createWithBatchMembersForEvent = (
  { dispatch, commit },
  { options }
) => {
  commit(types.API_REQUEST_START, 'createWithBatchMembersForEvent')

  return new Promise((resolve, reject) => {
    Promoter.createWithBatchMembersForEvent(options)
      .then((response) => {
        commit(types.ADD_PROMOTER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        // model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createWithBatchMembersForEvent,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
